.a4-comments__commentbox__form,
.a4-comments__child--list {
    fieldset {
        margin-bottom: 0;
    }
}

.a4-comments__textarea--big,
.a4-comments__textarea--small {
    width: 100%;
    overflow: hidden;
    max-height: 400px;
    min-height: 100px;
}

// comments and debate comments have different sized text boxes
.a4-comments__textarea--big {
    min-height: 100px;
}

.a4-comments__textarea--small {
    min-height: 46px;
}

.a4-comments__comment {
    border-top: solid 1px $gray-lightest;
    padding: 1.5 * $spacer 0 $spacer 0;

    mark > p {
        background-color: $brand-primary-tint;
        display: inherit;
    }
}

.a4-comments__box--left {
    padding-left: 0;

    @extend .col-md-2;
}

.a4-comments__box--right {
    border-left: 1px solid $gray-lightest;
    padding-right: 0;

    @extend .col-md-10;
}

.a4-comments__filters__parent {
    margin: 1.5 * $spacer 0 1.5 * $spacer 0;
}

.a4-comments__filters__parent--closed {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.a4-comments__filters__btn {
    @extend .btn--transparent;

    min-width: 100%;

    .fa-check {
        font-size: $font-size-sm;
    }
}

.a4-comments__dropdown-container {
    margin-right: $spacer;

    @media screen and (min-width: $breakpoint-xs) {
        margin-right: 0; // ensure inline with btns below
    }
}

.a4-comments__submit-input {
    @extend .btn--default;
}

.a4-comments__dropdown {
    text-align: right;

    i {
        transform: rotate(90deg);
        padding: 0.5 * $spacer;
    }
}

.a4-comments__filters {
    margin-top: $spacer;

    @media screen and (min-width: $breakpoint) {
        display: flex;
        justify-content: space-between;
    }
}

.a4-comments__filters__search {
    @extend .input-group;

    margin-bottom: 0.5 * $spacer;

    @media screen and (min-width: $breakpoint) {
        margin-right: 0.5 * $spacer;
    }
}

.a4-comments__filters__search-result {
    position: absolute;
    right: 60px;
    top: 7px;
}

.a4-comments__action-bar-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}

.a4-comments__action-bar {
    padding: 0.5 * $spacer 0;
}

.a4-comments__action-bar__btn {
    margin-left: 0.2 * $spacer !important;
    text-decoration: underline;

    @extend .btn--small;

    &:first-child {
        margin-left: 0 !important;
    }

    &:hover {
        text-decoration: none;
    }
}

.a4-comments__border--highlighted {
    border-left: 2px solid $brand-primary;
}

.btn.btn--none.a4-comments__read-btn {
    text-decoration: underline;
    margin-bottom: 0.5 * $spacer;

    &:hover,
    &:active {
        text-decoration: none;
        background-color: inherit;
    }
}

.a4-comments__text,
.a4-comments__author {
    margin: 1.2 * $spacer 0 1.2 * $spacer 0;
    overflow-wrap: break-word;
    white-space: pre-wrap;
}

.a4-comments__submission-date {
    font-style: italic;
    display: block;
}

.a4-comments__owner {
    background-color: $bg-light;
    border-top: solid 0.5rem $bg-light;
}

.a4-comments__anchor {
    display: block;
    position: relative;
    top: -100px;
    visibility: hidden;
    height: 1px;
}

.a4-comments__author {
    display: inline-block;
    margin: 0 (0.25 * $spacer) 0 0;
}

.a4-comments__moderator {
    font-size: $font-size-sm;
    font-style: italic;
    color: $text-color-gray;
}

.a4-comments__child--list {
    border-left: solid 1px $gray-lightest;
    margin: 0 0 $spacer (-$spacer);

    @media screen and (min-width: $breakpoint) {
        margin: 0 0 2.5 * $spacer 2.5 * $spacer;
    }

    form {
        padding-top: $spacer;
        border-top: solid 1px $gray-lightest;
    }

    .a4-comments__box--left {
        text-align: right;

        @extend .col-md-12;
    }

    .a4-comments__box--right {
        border-left: none;

        @extend .col-12;
    }

    .a4-comments__text {
        margin-top: $spacer;
    }
}

// categories and category badges
.a4-comments__category__input {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

.a4-comments__category__row {
    input[type="checkbox"] + span:before {
        font-family: "Font Awesome\ 5 Free", sans-serif;
        font-weight: 900;
        font-size: $font-size-xs;
        content: "\f067"; // plus
    }

    input[type="checkbox"]:checked + span:before {
        content: "\f00c"; // tick
    }
}

.badge.a4-comments__category__text,
.badge.a4-comments__badge {
    color: $text-color;
    background-color: $gray-lightest;
    border-radius: 1rem;
    text-decoration: none;
    font-size: $font-size-sm;
    font-weight: $font-weight-normal;
    font-family: $font-family-sans-serif;
    padding: 0.3 * $spacer 0.5 * $spacer;
    margin: 0.5 * $spacer 0.5 * $spacer 0.5 * $spacer 0;
}

.a4-comments__category {
    display: inline-block;
}

.a4-comments__badge {
    color: $gray;
}

.a4-comments__category__input:checked {
    + .a4-comments__category__que {
        background-color: $blue-light;
    }

    + .a4-comments__category__not {
        background-color: $purple-light;
    }

    + .a4-comments__category__sug {
        background-color: $green-light;
    }
}

.badge.a4-comments__badge--que {
    background-color: $blue-light;
}

.badge.a4-comments__badge--not {
    background-color: $orange-light;
}

.badge.a4-comments__badge--sug {
    background-color: $green-light;
}

.a4-comments__text--highlighted {
    border-left: solid 2px $brand-primary;
    padding-left: 0.5 * $padding;
}

.a4-comments__filters__text {
    @extend .col-sm-3;
    padding-left: 0;
    margin-top: 0.5 * $spacer;
    color: $gray;
}

.a4-comments__filters__show-btn {
    @extend .btn--transparent;

    & > i {
        padding-right: 0.5 * $spacer;
    }
}

.a4-comments__char-count {
    color: $gray !important;
    font-size: $font-size-sm !important;
    font-weight: normal !important;
}

.a4-comments__loading {
    text-align: center;
}

.modal-url-bar-button.active,
.modal-url-bar-button.active:hover {
    background-color: $brand-success;
}

.a4-comments__moderator-feedback__icon {
    flex: 0;
    transform: rotateX(180deg);
}

.a4-comments__moderator-feedback__content {
    margin-left: 0.5 * $spacer;
}

.a4-comments__moderator-feedback__moderator {
    font-weight: 600;
}

.a4-comments__moderator-feedback__text {
    color: $gray;
    margin: 0.25 * $spacer 0;
}

.a4-comments__moderator-feedback__date {
    color: $gray;
    margin: 0 0 0.5 * $spacer;
}

.a4-comments__moderator-feedback__container {
    display: flex;
    align-items: flex-start;
    margin: $spacer 0;
}
