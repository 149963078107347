.footer {
    background-color: $bg-dark;
}

.footer__link {
    padding-right: $padding;
    color: contrast-color($bg-dark);

    a {
        font-weight: normal;
        color: contrast-color($bg-dark);
    }
}

.footer__description,
.footer__description a {
    font-family: $font-family-serif;
    color: contrast-color($bg-dark);

    img {
        height: $font-size-md;
    }
}

.footer__no-banner > .row {
    justify-content: center;
}

.footer__margin-for-banner {
    margin-bottom: 3.5 * $spacer;

    @media screen and (min-width: $breakpoint) and (max-width: $breakpoint-md-down) {
        margin-bottom: 5.5 * $spacer;
    }
}

.navbar-brand {
    font-size: $font-size-md;
}

.footer-upper__text {
    font-weight: $demi-bold;
    color: $text-color-gray;
}

.footer-upper__navbar-nav {
    text-align: center;

    @media screen and (min-width: $breakpoint) {
        flex-flow: row wrap;
        justify-content: space-evenly;
    }

    .nav-item {
        padding-right: $spacer;
    }

    .navi-link {
        color: $text-color-gray;
    }
}
