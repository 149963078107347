$messages-margin-bottom: 25px;

.messages {
    padding: 0;
    width: 100%;
    margin-bottom: 0;

    li {
        display: block;
    }
}

.alert {
    margin: 0;
    padding: (1.2 * $padding) $padding;
    background-color: $brand-primary-tint;
    color: $text-color;
    text-align: center;
    border-radius: 0;
    flex: 1 1 auto;
}

.alert--success {
    background-color: lighten($brand-success, 55);
    color: $text-color;
}

.alert--error,
.alert--danger {
    background-color: lighten($brand-danger, 55);
    color: $text-color;
}

.alert--warning {
    background-color: lighten($brand-warning, 30);
    color: $text-color;
}

.alert__close {
    float: right;
    color: inherit;
    margin-right: $spacer;
}

.alert--small {
    padding: 0.5em;
}

.project-header,
.hero-unit {
    .messages + & {
        margin-top: -$messages-margin-bottom;
    }
}
