.follow__notification {
    .alert {
        margin-top: $r-spacer;
    }
}

// nasty overwrite, needs to be changed in a4

.follow > .btn--light,
.follow > .btn--primary {
    background-color: transparent;
    border: none;
}

.project-header--image {
    .follow > .btn--light,
    .follow > .btn--primary {
        color: $white;
        background-color: transparent;
        border: none;
    }
}
