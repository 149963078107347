.widget--datetimeinput {
    @include clearfix;
}

.datepicker {
    @extend .input-group__input;

    &::placeholder {
        opacity: 50%;
    }
}

// stylelint-disable selector-max-specificity
// need to override the default styling
.date-picker-control:link {
    background-image: none !important;
    min-width: 3em;
    z-index: 0;

    @extend .input-group__after;
    @extend .btn;
    @extend .btn--light;

    span:first-child {
        display: inline;

        &:before {
            font-family: "Font Awesome 5 Free", sans-serif;
            font-weight: 300; // to use the light version (formerly calendar-o)
            content: "\f133"; // calendar
        }
    }

    &:focus {
        outline: 2px solid Highlight !important;
        outline: 5px auto -webkit-focus-ring-color !important;
    }

    &:focus,
    &:hover {
        span:first-child:before {
            content: "\f073"; // calendar-alt
        }

        span {
            box-shadow: none !important;
            animation: none !important;
        }
    }
}
// stylelint-enable

.date-picker {
    font-family: $font-family-base;

    // overwrite gradient
    table,
    td {
        background: $body-bg;
    }

    .date-picker-unused {
        background: $bg-light;
    }

    .date-picker-highlight {
        color: $text-color;
    }

    .prev-but,
    .next-but {
        font-size: 0;

        &:before {
            font-family: "Font Awesome 5 Free", sans-serif;
            font-weight: 900;
            font-size: $font-size-lg;
            vertical-align: bottom;
        }
    }

    .prev-but:before {
        content: "\f104"; // angle-left
    }

    .prev-year:before {
        content: "\f100"; // angle-double-left
    }

    .next-but:before {
        content: "\f105"; // angle-right
    }

    .next-year:before {
        content: "\f101"; // angle-double-right
    }

    .date-picker-hover {
        border: none;

        background: $brand-primary;
        color: contrast-color($brand-primary);
        box-shadow: none;
        text-shadow: none;
    }
}

// The original styling causes a huge focus box in firefox
.fd-screen-reader.fd-screen-reader {
    @include visually-hidden;
    left: 0;
}
