$spacer-xl: 7.5rem;

.homepage__wrapper {
    background-color: $bg-light;
    margin: 0 $r-spacer 0 $r-spacer;

    @media screen and (min-width: $breakpoint-xxl) {
        max-width: $breakpoint-xxl;
        margin: auto;
    }

    .block {
        p {
            font-size: $font-size-md;
        }
    }
}

// single col cta styling - for headline
.homepage__wrapper .block-columns_cta:has(.block-row-1) {
    background-color: $brand-primary;
    color: $body-bg;
}

.homepage__wrapper .block-row-1 {
    @media screen and (min-width: $breakpoint-xs) {
        padding-block: $block-padding;
    }

    @media screen and (min-width: $breakpoint-md) {
        padding-block: $block-padding-md;
    }
}

.homepage__wrapper .block-row-3 {
    padding: $padding;
    background-color: $body-bg;

    @media screen and (min-width: $breakpoint-xs) {
        padding: $block-padding;
    }

    @media screen and (min-width: $breakpoint-md) {
        padding: $block-padding-md;
    }
}

.homepage-header {
    margin-bottom: 2 * $r-spacer;

    @media screen and (min-width: $breakpoint-xs) {
        margin-bottom: $spacer-xl;
    }
}

.homepage-header__image-wrapper {
    display: flex;
    justify-content: flex-end;
}

.homepage-header__image {
    position: relative;
    right: -$spacer;
    width: 100%;
    object-fit: cover;

    @media screen and (min-width: $breakpoint-xxl) {
        right: 3 * -$spacer;
    }
}

.homepage-header__teaser {
    font-family: $font-family-serif;
    font-weight: normal;

    b {
        font-family: $font-family-base;
    }
}
